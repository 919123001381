@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import fonts */
@import '@fontsource/unifrakturmaguntia';
@import '@fontsource/cinzel-decorative';
@import '@fontsource/playfair-display';

@keyframes rotate{
  from{ transform: rotate(-360deg); }
  to{ transform: rotate(360deg); }
}

img{
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@layer base {
  body {
    @apply bg-stone-200 text-stone-800;
  }
}

@font-face {
  font-family: 'WeThePeople';
  src: url('../public/wethepeople.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Custom utility classes */
@layer utilities {
  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .paper-texture {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='paper'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.5' numOctaves='5' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='200' height='200' filter='url(%23paper)' opacity='1'/%3E%3C/svg%3E");
  }
}